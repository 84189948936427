import * as React from "react";
// @ts-ignore
import {Col, Container, Form, Row, Button} from "react-bootstrap";

import * as style from './TwoSidedTeaser.module.scss';
import * as navStyle from '../../../../components/Navigation/Navigation.module.scss';
import * as contentStyle from '../../../../components/Content/Content.module.scss';
import {formButton, formInput, formLabel} from "../../../login/index.module.scss";
import {faArrowRight, faPlus, faCheck} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as mainStyle from "../../index.module.scss";
import {Link, navigate} from "gatsby";
import {Client} from "../../../../services/ApiService";
import {connect, ConnectedProps} from "react-redux";
import {FC} from "react";
import {faCalendar} from "@fortawesome/pro-light-svg-icons";

const mapState = ({member, auth}) => ({
    member,
    auth
})
const connector = connect(mapState, null);

type PropsFromRedux = ConnectedProps<typeof connector>

type TwoSidedTeaserProps = PropsFromRedux & {
    title1: string,
    title2: string,
    description1: string,
    description2: string,

    list1item1: string,
    list1item2: string,
    list1item3: string,

    list2item1: string,
    list2item2: string,
    list2item3: string,
    PDFLink: string,
}


const TwoSidedTeaser: FC<TwoSidedTeaserProps> = (props: TwoSidedTeaserProps) => {
    return <>
        <Row className={mainStyle.box}>
            <Col lg={6} className={style.sideLeft}>
                <div className={style.sideTitle}>
                    {props.title1}
                </div>
                <div className={style.sideList}>
                    <div className={style.sideListItem}>
                        <div className={style.customBorder}><FontAwesomeIcon icon={faCheck}/></div>
                        <div className={style.listItemText}>{props.list1item1}</div>
                    </div>
                    <div className={style.sideListItem}>
                        <div className={style.customBorder}><FontAwesomeIcon icon={faCheck}/></div>
                        <div className={style.listItemText}>{props.list1item2}

                        </div>
                    </div>
                    <div className={style.sideListItem}>
                        <div className={style.customBorder}><FontAwesomeIcon icon={faCheck}/></div>
                        <div className={style.listItemText}>{props.list1item3}

                        </div>
                    </div>
                </div>
                <div className={style.description}>
                    {props.description1}
                </div>

                <Button className={style.customBTN} onClick={() => {window.open(props.PDFLink, '_blank');
                    if(props.member.MemberID) {
                        Client.Auth.authGetNoID(props.auth.Key, 'Member/' + props.member.MemberID + '/gtagData').then((res) => {
                            // @ts-ignore
                            if (window.dataLayer) {
                                let dataLayerPush = {
                                    'cicData': {
                                        'cic_member_mail': res.data.MemberMail ?? 'user not logged in',
                                        'cic_country': res.data.Country ?? 'NOCOUNTRY',
                                        'cic_plz': res.data.plz ?? 'NOPLZ',
                                        'cic_location': res.data.City ?? 'NOCITY',
                                    },
                                    'event': 'fs.landingpage_checkliste'
                                };

                                console.log(dataLayerPush)

                                // @ts-ignore
                                window.dataLayer.push(dataLayerPush);
                            }
                        })
                    } else {
                        // @ts-ignore
                        if (window.dataLayer) {
                            let dataLayerPush = {
                                'cicData': {
                                    'cic_member_mail': 'user not logged in',
                                    'cic_country': 'NOCOUNTRY',
                                    'cic_plz': 'NOPLZ',
                                    'cic_location': 'NOCITY',
                                },
                                'event': 'fs.landingpage_checkliste'
                            };

                            console.log(dataLayerPush)

                            // @ts-ignore
                            window.dataLayer.push(dataLayerPush);
                        }
                    }
                }}>
                    <Row>
                        <Col lg={12} className={style.pinkText}>Jetzt Checkliste ansehen</Col>
                    </Row>
                </Button>
            </Col>
            <Col lg={6} className={style.sideRight}>
                <div className={style.sideTitle}>
                    {props.title2}
                </div>
                <div className={style.sideList}>
                    <div className={style.sideListItem}>
                        <div className={style.customBorder}>1.</div>
                        <div className={style.listItemText}>{props.list2item1}
                        </div>
                    </div>
                    <div className={style.sideListItem}>
                        <div className={style.customBorder}>2.</div>
                        <div className={style.listItemText}> {props.list2item2}

                        </div>
                    </div>
                    <div className={style.sideListItem}>
                        <div className={style.customBorder}>3.</div>
                        <div className={style.listItemText}>{props.list2item3}

                        </div>
                    </div>
                </div>
                <div className={style.description}>
                    <p>Du hast Fragen? Schreib uns eine E-Mail an: <a
                        href={'mailto:info@fairstaerkt.at'}><u>info@fairstaerkt.at</u></a> oder ruf uns einfach an: <a
                        href={'tel:+43 681 206 831 53'}><u>+43 681 206 831 53</u></a></p>
                </div>
                <Button className={style.customBTN} onClick={() => {
                    if (props.member.MemberID) {
                        Client.Auth.authGetNoID(props.auth.Key, 'Member/' + props.member.MemberID + '/gtagData')
                            .then((res) => {
                                // @ts-ignore
                                if (window.dataLayer) {
                                    let dataLayerPush = {
                                        'cicData': {
                                            'cic_member_mail': res.data.MemberMail ?? 'user not logged in',
                                            'cic_country': res.data.Country ?? 'NOCOUNTRY',
                                            'cic_plz': res.data.plz ?? 'NOPLZ',
                                            'cic_location': res.data.City ?? 'NOCITY',
                                        },
                                        'event': 'fs.landingpage_startnow'
                                    };

                                    console.log(dataLayerPush);

                                    const dataLayerPushPromise = new Promise((resolve) => {
                                        // @ts-ignore
                                        window.dataLayer.push(dataLayerPush);
                                        resolve();
                                    });

                                    dataLayerPushPromise.then(() => {
                                        navigate('/register/vendor');
                                    });
                                } else {
                                    navigate('/register/vendor');
                                }
                            });

                    } else {
                        if (window.dataLayer) {
                            let dataLayerPush = {
                                'cicData': {
                                    'cic_member_mail': 'user not logged in',
                                    'cic_country': 'NOCOUNTRY',
                                    'cic_plz': 'NOPLZ',
                                    'cic_location': 'NOCITY',
                                },
                                'event': 'fs.landingpage_startnow'
                            };

                            console.log(dataLayerPush);

                            const dataLayerPushPromise = new Promise((resolve) => {
                                // @ts-ignore
                                window.dataLayer.push(dataLayerPush);
                                resolve();
                            });

                            dataLayerPushPromise.then(() => {
                                navigate('/register/vendor');
                            });
                        } else {
                            navigate('/register/vendor');
                        }

                    }
                }}>
                    <div className={style.formBTN}>
                        <div className={style.btnText}>Jetzt Starten <FontAwesomeIcon icon={faArrowRight} />
                        </div>
                    </div>
                </Button>

            </Col>
        </Row>
    </>;
}

export default connector(TwoSidedTeaser);
